<template>
  <div>
    <slot />
    <div class="menu">
      <div
        v-for="menu in menuList"
        :key="menu.route"
        @click="clickMenu(menu.route)"
        :class="{ active: menu.route === selectedMenu }"
      >
        <img v-show="menu.route === selectedMenu" :src="menu.imgActive" />
        <img v-show="menu.route !== selectedMenu" :src="menu.img" />
        <div>{{ menu.name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import shopImg from "@/assets/bottomMenu/shop.svg";
import shopImgActive from "@/assets/bottomMenu/shop_active.svg";
import boxImg from "@/assets/bottomMenu/box.svg";
import boxImgActive from "@/assets/bottomMenu/box_active.svg";
import settingsImg from "@/assets/bottomMenu/settings.svg";
import settingsImgActive from "@/assets/bottomMenu/settings_active.svg";

export default {
  name: "BottomMenuLayout",
  data() {
    return {
      selectedMenu: "",
      menuList: [
        {
          name: "ร้านค้า",
          img: shopImg,
          imgActive: shopImgActive,
          route: "/products",
        },
        {
          name: "สินค้า",
          img: boxImg,
          imgActive: boxImgActive,
          route: "/manage-product",
        },
        {
          name: "ตั้งค่า",
          img: settingsImg,
          imgActive: settingsImgActive,
          route: "/settings",
        },
      ],
    };
  },
  watch: {
    $route() {
      this.selectedMenu = this.$route.matched[0].path;
    },
  },
  methods: {
    logoUrl(name) {
      return require(`../assets/bottomMenu/${name}`);
    },
    clickMenu(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({ path: routeName });
      }
    },
  },
  created() {
    this.selectedMenu = this.$route.matched[0].path;
  },
};
</script>

<style lang="scss" scoped>
.menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  background: #fff;
  z-index: 2;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.1);
  > div {
    font-size: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > img {
      width: 28px;
      padding: 4px;
      aspect-ratio: 1/1;
      // margin: 0 auto;
      // display: block;
    }
  }
}
</style>